import * as React from "react";
import { FC, useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react";

import { A, F, pipe } from "@mobily/ts-belt";
import { Space } from "@smplrspace/smplr-loader/dist/generated/smplr";
import { Button, Modal, Select, message } from "antd";
import errorHandler from "errorHandler";

import useSensorflowLocation from "hooks/use-sensorflow-location";
import { isEmpty, isNil } from "lodash";
import moment from "moment";
import {
  GetGroupsForDropdownQuery,
  GetNonEmptyKeysRoomsByGroupQuery,
  RoomStatusByGroupSubscription,
  Sensorflow_Mapping_Coordinates_Constraint,
  SimpleOnlineGatewayHealthDataByLocationSubscription,
  useDeleteMappingCoordinatesMutation,
  useGetGroupsForDropdownQuery,
  useGetNonEmptyKeysRoomsByGroupLazyQuery,
  useRoomStatusByGroupSubscription,
  useSaveMappingCoordinatesMutation,
  useSimpleOnlineGatewayHealthDataByLocationSubscription,
  useLocationMetadataLazyQuery,
  GetGatewayAssociatedNodesQuery,
  useGetGatewayAssociatedNodesLazyQuery,
  GetAllPositionsInLiveEntryModeSubscription,
  useGetAllPositionsInLiveEntryModeSubscription,
  useOnEnterLiveDataModeConfigMutation,
  useOnExitLiveDataModeConfigMutation,
} from "pacts/app-webcore/hasura-webcore.graphql";
import { GroupType } from "pages/Groups/Components/GroupModal";
import { useAuth0 } from "services/auth/authService";
import MapperHeader from "./MapperHeader";
import SpaceViewer from "./SpaceViewer";
import { Sensor, getSensorsFromDb, getColor } from "./sensors";
import { DispatchPointAction, DispatchPolygonAction, Point, Polygon, SmplrCoord3d, DataLayer } from "./types";

const MapVisualization: FC = () => {
  const [nodeData, setNodeData] = useState<RoomStatusByGroupSubscription["positions"]>();
  const [gatewayData, setGatewayData] = useState<SimpleOnlineGatewayHealthDataByLocationSubscription["gateways"]>();
  const [groupNames, setGroupNames] = useState<GetGroupsForDropdownQuery["positions"]>([]);
  const [associatedNodes, setAssociatedNodes] = useState<GetGatewayAssociatedNodesQuery["gatewayToPositionMappings"]>();
  const [selectedGroup, setSelectedGroup] = useState<string>("");
  const [space, setSpace] = useState<Space>();
  const [smplr, setSmplr] = useState<any>();
  const [sensors, setSensors] = useState<Sensor[]>([]);
  const [queryClient, setQueryClient] = useState<any>();
  const [signalData, setSignalData] = useState<any>();

  const { locationId } = useSensorflowLocation();
  const { user } = useAuth0();
  const [mapMode, setMapMode] = useState<boolean>(false);
  const [ongoingMapping, setOngoingMapping] = useState<boolean>(false);
  const [dataLayer, setDataLayer] = useState<string>("");
  const [liveDataModeBtn, setLiveDataModeBtn] = useState<string>("Start Live Data Mode");
  const [chosenGatewayId, setChosenGatewayId] = useState<string>("");
  const [positionsInLiveData, setPositionsInLiveData] =
    useState<GetAllPositionsInLiveEntryModeSubscription["gatewayToPositionMappings"]>();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const entityToMap = useRef<{
    positionId: string;
    positionName: string;
    coordinates: any;
    nodeSlots?: any;
    gatewayId?: any;
  }>();
  const mapType = useRef<string>();
  const [smplrspaceId, setSmplrspaceId] = useState<string>("");
  const CLIENT_TOKEN = process.env.SMPLRSPACE_CLIENT_TOKEN || "pub_a428f4950b2f491099feeb9b40403d14";
  const ORGANIZATION_ID = process.env.SMPLRSPACE_ORGANIZATION_ID || "376f25aa-6faa-44d1-abca-1d5ef29ed148";
  const dataLayerOptions = Object.keys(DataLayer);

  const { loading } = useGetGroupsForDropdownQuery({
    variables: {
      where: {
        locationId: { _eq: locationId },
        positionType: {
          _in: [GroupType.FLOOR, GroupType.GROUP, GroupType.ZONE],
        },
        deletedAt: { _is_null: true },
      },
    },
    onCompleted: ({ positions: groups }) => {
      setGroupNames(groups);
    },
  });

  const getAssetMapFromDb = async (
    positions: GetNonEmptyKeysRoomsByGroupQuery["positions"],
    gateways: GetNonEmptyKeysRoomsByGroupQuery["gateways"]
  ) => {
    const spaceDb = await getSensorsFromDb(positions, gateways);
    setSensors(spaceDb);
  };

  const [handleGroupChange] = useGetNonEmptyKeysRoomsByGroupLazyQuery({
    variables: {
      locationId,
      groupId: selectedGroup,
    },
    onCompleted(data) {
      getAssetMapFromDb(data.positions, data.gateways);
    },
  });

  const [getSpaceIdFromDb] = useLocationMetadataLazyQuery({
    variables: {
      locationId,
    },
    onCompleted: (data) => {
      if (data.locationMetadata?.smplrspaceId) setSmplrspaceId(data.locationMetadata?.smplrspaceId);
      else message.error("This property has no available map.");
    },
  });

  useEffect(() => {
    if (locationId && !smplrspaceId) {
      getSpaceIdFromDb();
    }
  }, [locationId, getSpaceIdFromDb, smplrspaceId]);

  useRoomStatusByGroupSubscription({
    variables: {
      keyId: selectedGroup,
      positionType: "key",
      filterSlotMappings: { decomissionedTime: { _is_null: true } },
    },
    onSubscriptionData: (data) => setNodeData(data.subscriptionData.data?.positions),
  });

  useSimpleOnlineGatewayHealthDataByLocationSubscription({
    variables: {
      locationId,
      offlineTime: moment().add(-5, "minutes").utc().format(),
    },
    onSubscriptionData: (data) => setGatewayData(data.subscriptionData.data?.gateways),
  });

  useGetAllPositionsInLiveEntryModeSubscription({
    variables: {
      gatewayId: chosenGatewayId,
    },
    onSubscriptionData: (data) => setPositionsInLiveData(data.subscriptionData.data?.gatewayToPositionMappings),
  });

  const [getAssociatedNodes] = useGetGatewayAssociatedNodesLazyQuery({
    variables: {
      gatewayId: chosenGatewayId,
    },
    onCompleted: (data) => {
      if (data.gatewayToPositionMappings) setAssociatedNodes(data.gatewayToPositionMappings);
    },
  });

  const [enterLiveDataMode] = useOnEnterLiveDataModeConfigMutation({
    onCompleted: () => setIsModalVisible(true),
    onError: (error: any) => errorHandler.handleError(error),
  });

  const [exitLiveDataMode] = useOnExitLiveDataModeConfigMutation({
    onCompleted: () => message.success("Exited live data mode"),
    onError: (error: any) => errorHandler.handleError(error),
  });

  /**
   * Handler for Live Data Mode
   */
  useMemo(() => {
    const tempPos: string[] = [];
    positionsInLiveData?.map((p) => {
      if (p.position?.positionConfiguration?.length > 0) {
        tempPos.push(p.position?.positionConfiguration[0].positionId);
      }
      return true;
    });
    if (tempPos.length === 0) setLiveDataModeBtn("Start Live Data Mode");
    else setLiveDataModeBtn("Exit Live Data Mode");
    getAssociatedNodes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenGatewayId, positionsInLiveData]);

  const handleLiveDataMode = useCallback(async () => {
    const nodesToExit: string[] = [];
    positionsInLiveData?.map((p) => {
      if (p.position?.positionConfiguration?.length > 0) {
        nodesToExit.push(p.position?.positionConfiguration[0].positionId);
      }
      return true;
    });
    if (nodesToExit.length === 0) {
      const nodesToEnter: string[] = [];
      associatedNodes?.map((a) => {
        nodesToEnter.push(a.positionId);
        return true;
      });
      await enterLiveDataMode({
        variables: {
          positionIds: nodesToEnter,
          userId: user.sub,
        },
      });
    } else {
      await exitLiveDataMode({
        variables: {
          positionIds: nodesToExit,
          userId: user.sub,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enterLiveDataMode, exitLiveDataMode, associatedNodes, positionsInLiveData]);

  /**
   * Data parser
   */

  useMemo(() => {
    handleGroupChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroup]);

  useMemo(async () => {
    if (!sensors || !nodeData || !selectedGroup || !gatewayData || mapMode) return;
    space?.removeAllDataLayers();
    const signalDataTemp: any[] = [];
    gatewayData?.map((gw) => {
      const gwAsset = sensors.find((s) => s.name === gw.gatewayName);
      if (!gwAsset?.position) return true;
      let gwValue: number = 0;
      let gatewayStatus: string = "OFFLINE";
      if (gw.gatewayHealthData.length > 0) {
        gwValue = 100;
        gatewayStatus = "ONLINE";
      }
      signalDataTemp.push({
        id: gwAsset?.id,
        value: gwValue,
        position: { ...gwAsset?.position },
        uuid: gwAsset?.id,
        name: gwAsset?.name,
        status: gatewayStatus,
      });
      return true;
    });
    nodeData?.map((node) => {
      node?.rooms.map((roomId) => {
        roomId.slotMappings.map((n) => {
          const slotId = n.slot?.id;
          const assetData = sensors.find((m) => m.id === slotId);
          if (!assetData?.position) return true;
          signalDataTemp.push({
            id: assetData?.id,
            value: n.node.nodeJoinStrengthLive?.signalStrength,
            position: { ...assetData?.position },
            uuid: assetData?.id,
            name: assetData?.name,
          });
          return true;
        });
        return true;
      });
      return true;
    });
    setSignalData(signalDataTemp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodeData, sensors, selectedGroup, gatewayData]);

  const [saveMappingCoordinates] = useSaveMappingCoordinatesMutation({
    onError: errorHandler.handleError,
  });

  const [deleteMappingCoordinates] = useDeleteMappingCoordinatesMutation({
    onCompleted: async () => {
      message.success("Mapping removed successfully!");
    },
    onError: errorHandler.handleError,
  });

  // START OF MAPPER ACTIONS
  // polygon
  const [polygons, dispatchPolygon] = useReducer((innerPolygons: Polygon[], action: DispatchPolygonAction) => {
    switch (action.type) {
      case "addPolygon": {
        const existingMapping = innerPolygons.find((p: any) => p.id === entityToMap.current?.positionId);
        if (existingMapping) {
          return innerPolygons.map((r) => (r.id === action.id ? { ...r, coordinates: action.coordinates } : r));
        }
        return A.append(innerPolygons, {
          id: action.id,
          name: action.name,
          coordinates: action.coordinates || [],
        });
      }
      case "addCoordinate":
        return innerPolygons.map((r) =>
          r.id === action.id ? { ...r, coordinates: [...r.coordinates, action.coordinate] } : r
        );
      case "updateCoordinates":
        return innerPolygons.map((r) => (r.id === action.id ? { ...r, coordinates: action.coordinates } : r));
      case "removePolygon":
        return pipe(
          innerPolygons,
          A.reject((r: Polygon) => r.id === action.id),
          F.toMutable
        );
      case "clear":
        return [];
      default:
        return innerPolygons;
    }
  }, []);

  // point
  const [points, dispatchPoint] = useReducer((innerPoints: Point[], action: DispatchPointAction) => {
    switch (action.type) {
      case "add": {
        const existingMapping = innerPoints.find((p: Point) => p.id === action.point.id);
        if (existingMapping && !isNil(existingMapping.position)) {
          return innerPoints.map((pt) =>
            pt.id === action.point.id ? { ...pt, coordinates: action.point.position } : pt
          );
        }
        return [...innerPoints, action.point];
      }
      case "update":
        return innerPoints.map((pt) => (pt.id === action.id ? { ...pt, ...action.updates } : pt));
      case "remove":
        return pipe(
          innerPoints,
          A.reject((r: Point) => r.id === action.id),
          F.toMutable
        );
      case "clear":
        return [];
      default:
        return innerPoints;
    }
  }, []);
  // END OF MAPPER ACTIONS

  // START OF DATA LAYERS
  // Heat Map
  useMemo(() => {
    if (!space || !smplr || !selectedGroup || signalData.length === 1 || mapMode) return;
    if (dataLayer === DataLayer.SIGNAL) {
      space.addDataLayer({
        id: "hm",
        type: "heatmap",
        style: "spheres",
        data: signalData,
        value: (d: any) => d.value,
        color: smplr.Color.numericScale({
          name: smplr.Color.NumericScale.RdYlGn,
          domain: [0, 100],
          invert: false,
        }),
        confidenceRadius: 1,
        gridSize: 0.1,
        disableElevationCorrection: true,
        elevation: 1,
        squishFactor: -2,
      });
      return () => {
        space.removeDataLayer("hm");
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signalData, space, smplr, mapMode, dataLayer]);

  // Labels
  useMemo(() => {
    if (!space || !smplr || !selectedGroup || signalData.length === 1 || mapMode) return;
    if (dataLayer === DataLayer.LABEL) {
      space.addDataLayer({
        id: "labels",
        type: "point",
        shape: "sphere",
        data: signalData,
        tooltip: (e: any) => {
          if (e.status === undefined) return `${e.name} - ${e.value}`;
          return `${e.name} - ${e.status}`;
        },
        color: (c: any) => getColor(c.value),
        persistentTooltip: true,
        diameter: 0.8,
      });
      return () => {
        space.removeDataLayer("labels");
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signalData, space, smplr, mapMode]);

  // Polygon for Mapping
  useEffect(() => {
    if (!space) {
      return;
    }
    space.addDataLayer({
      id: "polygons",
      type: "polygon",
      data: pipe(
        polygons,
        A.reject((p: Polygon) => A.isEmpty(p.coordinates)),
        F.toMutable
      ),
      height: 3.05,
      alpha: 0.5,
      tooltip: (d) => d.name,
      onDrop: ({ data, coordinates }) =>
        dispatchPolygon({
          type: "updateCoordinates",
          id: data.id,
          coordinates,
        }),
    });
  }, [space, polygons]);

  // points
  useEffect(() => {
    if (!space) {
      return;
    }
    space.addDataLayer({
      id: "points",
      type: "point",
      shape: "sphere",
      data: points,
      diameter: 0.5,
      anchor: "bottom",
      tooltip: (d) => d.name,
      onDrop: ({ data, position }) => {
        setOngoingMapping(true);
        dispatchPoint({
          type: "update",
          id: data.id,
          updates: { position },
        });
      },
    });
  }, [space, points]);

  // END OF DATA LAYERS

  const onReady = useCallback(async (spaceCb: any, smplrCb: any) => {
    setSpace(spaceCb);
    setSmplr(smplrCb);
    const client = new smplrCb.QueryClient({
      organizationId: ORGANIZATION_ID,
      clientToken: CLIENT_TOKEN,
    });
    setQueryClient(client);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const activatePickingMode = useCallback(() => {
    if (!space || !queryClient) {
      return;
    }
    setMapMode(true);
    // clear all the data layers first
    space.removeAllDataLayers();
    // switch to 2D mode for easier viewing
    space.setMode("2d");
    space.enablePickingMode({
      onPick: async ({ coordinates }) => {
        if (!entityToMap.current || (!entityToMap.current.positionId && !entityToMap.current.gatewayId)) {
          message.error("Select an entity to be mapped!");
          return;
        }

        if (!mapType.current) {
          message.error("Select type of mapping!");
          return;
        }

        setOngoingMapping(true);

        switch (mapType.current) {
          case "room": {
            const room = await queryClient.getRoomAtPoint({
              spaceId: smplrspaceId,
              point: coordinates,
            });
            if (!room) {
              return;
            }
            dispatchPolygon({
              type: "addPolygon",
              id: entityToMap.current.positionId,
              name: entityToMap.current.positionName,
              coordinates: room.room,
            });
            break;
          }
          case "polygon":
            dispatchPolygon({
              type: "addCoordinate",
              id: entityToMap.current.positionId,
              name: entityToMap.current.positionName,
              coordinate: coordinates,
            });
            break;
          case "point":
            dispatchPoint({
              type: "add",
              point: {
                id: entityToMap.current.gatewayId,
                name: entityToMap.current.positionName,
                position: coordinates,
                type: "gateway",
              },
            });
            break;
          default:
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [space, queryClient, entityToMap]);

  const deactivatePickingMode = useCallback(() => {
    if (!space) {
      return;
    }
    setMapMode(false);
    space.setMode("3d");
    space.removeAllDataLayers();
    space.disablePickingMode();
  }, [space]);

  const setEntityToMapHandler = (
    e: { positionId: string; positionName: string; coordinates: any; nodeSlots?: any; gatewayId?: string } | undefined
  ) => {
    entityToMap.current = e;
    dispatchPolygon({ type: "clear" });
    dispatchPoint({ type: "clear" });

    if (!e?.gatewayId) {
      // draw the polygon mapping
      dispatchPolygon({
        type: "addPolygon",
        id: entityToMap.current.positionId,
        name: entityToMap.current.positionName,
        coordinates: entityToMap.current?.coordinates,
      });

      // map node slots if there are any
      if (entityToMap.current && entityToMap.current.nodeSlots && entityToMap.current.nodeSlots.length > 0) {
        const { nodeSlots } = entityToMap.current;
        nodeSlots.map((ns: any) => {
          if (ns?.slot?.mappingCoordinates?.coordinates?.length > 0) {
            dispatchPoint({
              type: "add",
              point: {
                id: ns.slot?.id,
                name: ns.slotName,
                position: ns.slot.mappingCoordinates.coordinates[0],
                type: "slot",
              },
            });
          }
          return true;
        });
      }
    } else if (e.gatewayId) {
      if (entityToMap.current?.coordinates) {
        dispatchPoint({
          type: "add",
          point: {
            id: entityToMap.current.gatewayId,
            name: entityToMap.current.positionName,
            position: entityToMap.current.coordinates,
            type: "gateway",
          },
        });
      }
    }
  };

  const onMapTypeChangeHandler = (e: string) => {
    if (
      entityToMap.current &&
      e === "polygon" &&
      !polygons.find((p: any) => p.id === entityToMap.current?.positionId)
    ) {
      dispatchPolygon({
        type: "addPolygon",
        id: entityToMap.current.positionId,
        name: entityToMap.current.positionName,
      });
    }
    mapType.current = e;
  };

  const onResetHandler = () => {
    space?.removeAllDataLayers();
    dispatchPoint({
      type: "clear",
    });
    dispatchPolygon({
      type: "clear",
    });
  };

  const onDoneHandler = async () => {
    if (polygons.length && polygons.length > 0) {
      const keysAndRoomsData = polygons.map((p: any) => {
        return {
          positionId: p.id,
          coordinates: p.coordinates,
        };
      });

      const res = await saveMappingCoordinates({
        variables: {
          mappingCoordinates: keysAndRoomsData,
          constraint: Sensorflow_Mapping_Coordinates_Constraint.MappingCoordinatesPositionIdKey,
        },
      });
      if ((res.data?.insert_sensorflow_mapping_coordinates?.affected_rows ?? 0) > 0) {
        message.success("Keys and Rooms mappings saved!");
      }
    }

    if (points.length && points.length > 0) {
      const nodeSlots = points.filter((p) => p.type === "slot");
      const gateways = points.filter((p) => p.type === "gateway");

      const nodeSlotsData = nodeSlots.map((ns: any) => {
        return {
          slotId: ns.id,
          coordinates: [ns.position],
        };
      });

      const gatewaysData = gateways.map((g: any) => {
        return {
          gatewayId: g.id,
          coordinates: [g.position],
        };
      });

      if (nodeSlotsData.length > 0) {
        const res = await saveMappingCoordinates({
          variables: {
            mappingCoordinates: nodeSlotsData,
            constraint: Sensorflow_Mapping_Coordinates_Constraint.MappingCoordinatesSlotIdKey,
          },
        });
        if ((res.data?.insert_sensorflow_mapping_coordinates?.affected_rows ?? 0) > 0) {
          message.success("Node slot mappings saved!");
        }
      }

      if (gatewaysData.length > 0) {
        const res = await saveMappingCoordinates({
          variables: {
            mappingCoordinates: gatewaysData,
            constraint: Sensorflow_Mapping_Coordinates_Constraint.MappingCoordinatesGatewayIdKey,
          },
        });
        if (
          res.data &&
          res.data.insert_sensorflow_mapping_coordinates &&
          res.data?.insert_sensorflow_mapping_coordinates?.affected_rows > 0
        ) {
          message.success("Gateway mappings saved!");
        }
      }
    }
    setOngoingMapping(false);
    entityToMap.current = undefined;
  };

  const onMappingDeleteHandler = async (id: string, entityType: string) => {
    const filters = [];
    switch (entityType) {
      case "position":
        filters.push({ positionId: { _eq: id } });
        break;
      case "gateway":
        filters.push({ gatewayId: { _eq: id } });
        break;
      case "slot":
        filters.push({ slotId: { _eq: id } });
        break;
      default:
    }
    await deleteMappingCoordinates({
      variables: {
        filter: filters,
      },
    });
    if (entityType === "position") {
      dispatchPolygon({ type: "removePolygon", id });
    } else if (entityType === "gateway" || entityType === "slot") {
      dispatchPoint({ type: "remove", id });
    }
  };

  const onAutoMapNodeSlotHandler = async (nodeSlots: any) => {
    let offset = 0;
    let toggleOffset = false;

    const center: SmplrCoord3d = await queryClient.getPolygonCenter({
      polygon: entityToMap.current?.coordinates,
    });

    if (nodeSlots.length <= 0) return;
    nodeSlots.map((ns: GetNonEmptyKeysRoomsByGroupQuery["positions"][0]["rooms"][0]["slotMappings"][0]) => {
      let newCenter = toggleOffset ? { ...center, x: (center.x += offset) } : { ...center, z: (center.z += offset) };
      // retry 5 times
      for (let i = 0; i < 5; i += 1) {
        newCenter = toggleOffset ? { ...center, x: (center.x += offset) } : { ...center, z: (center.z += offset) };
        const withinBounds = queryClient.isPointInPolygon({
          point: newCenter,
          polygon: entityToMap.current?.coordinates,
        });
        if (withinBounds) {
          break;
        }
        toggleOffset = !toggleOffset;
        offset -= 0.1;
      }

      dispatchPoint({ type: "add", point: { id: ns.slot?.id, name: ns.slotName, position: newCenter, type: "slot" } });
      offset += 0.1;
      toggleOffset = !toggleOffset;

      return true;
    });
    setOngoingMapping(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <MapperHeader
        onClickHandler={mapMode ? deactivatePickingMode : activatePickingMode}
        mapMode={mapMode}
        setEntityToMap={setEntityToMapHandler}
        mapTypeChangeHandler={onMapTypeChangeHandler}
        ongoingMapping={ongoingMapping}
        onResetHandler={onResetHandler}
        onDoneHandler={onDoneHandler}
        onMappingDeleteHandler={onMappingDeleteHandler}
        onAutoMapNodeSlotHandler={onAutoMapNodeSlotHandler}
      />
      {mapMode && entityToMap.current && (
        <p className="font-italic fs-sm">Now mapping: {entityToMap.current?.positionName}</p>
      )}
      <hr />
      <div className="d-flex mb-m margin-0">
        {!mapMode && (
          <>
            <Select
              className="w-15 m-0"
              disabled={loading}
              placeholder="Group"
              data-testid="group-dropdown"
              showSearch
              filterOption={(input, option) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              onSelect={(e) => {
                setSelectedGroup(e);
              }}
            >
              <Select.Option value={undefined}>-</Select.Option>
              {groupNames?.map((option) => (
                <Select.Option key={option.positionName} value={option.positionId}>
                  {option.positionName}
                </Select.Option>
              ))}
            </Select>
            <Select
              className="w-15 ml-m"
              disabled={loading}
              placeholder="Data Layer"
              data-testid="datalayer-dropdown"
              showSearch
              filterOption={(input, option) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              onSelect={(e) => setDataLayer(e)}
            >
              <Select.Option value={undefined}>-</Select.Option>
              {dataLayerOptions?.map((option) => (
                <Select.Option key={option} value={option}>
                  {option}
                </Select.Option>
              ))}
            </Select>
            <Select
              className="w-15 ml-m"
              disabled={loading}
              placeholder="Gateway"
              data-testid="gateway-dropdown"
              showSearch
              filterOption={(input, option) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              onSelect={(e) => setChosenGatewayId(e)}
            >
              <Select.Option value={undefined}>-</Select.Option>
              {gatewayData?.map((option) => (
                <Select.Option key={option.gatewayName} value={option.gatewayId}>
                  {option.gatewayName}
                </Select.Option>
              ))}
            </Select>
            <Button
              className="ml-m"
              type="primary"
              data-testid="livedatabtn"
              disabled={isEmpty(chosenGatewayId) || positionsInLiveData?.length === 0}
              onClick={handleLiveDataMode}
            >
              {liveDataModeBtn}
            </Button>
            <Modal
              title="Starting live data mode"
              open={isModalVisible}
              onOk={handleOk}
              cancelButtonProps={{ style: { display: "none" } }}
              centered
              destroyOnClose
            >
              <p>It will take 15-20 mins for nodes to enter live data mode.</p>
            </Modal>
          </>
        )}
      </div>
      <div className="smplr-wrapper" data-testid="smplr-wrapper">
        {smplrspaceId && (
          <SpaceViewer
            env="prod"
            mode="3d"
            onReady={onReady}
            spaceId={smplrspaceId}
            clientToken={CLIENT_TOKEN}
            organizationId={ORGANIZATION_ID}
          />
        )}
      </div>
    </>
  );
};

export default MapVisualization;
